import Swiper, {Navigation, Pagination} from 'swiper';
import 'swiper/css/bundle';
import 'select2';

Swiper.use([Navigation, Pagination]);

$(document).ready(function () {
    // drop open and hide when click outside
    var links = '.js-dropdown-link',
        innerElements = '.js-dropdown';

    $(document).on('click', links, function (e) {
        if ($(this).hasClass('active')) {
            $(links).filter('a[href="' + this.hash + '"]').removeClass("active");
            $(innerElements).filter(this.hash).removeClass('open');
        } else {
            $(links).removeClass('active').filter('a[href="' + this.hash + '"]').addClass('active');
            $(innerElements).removeClass('open').filter(this.hash).addClass('open');
            $(links).add(innerElements).off();
        }
        e.preventDefault();
    }).on('mouseup touchstart keyup', function (e) {
        if (!$(links).add(innerElements).is(e.target) && $(links).add(innerElements).has(e.target).length === 0 || e.keyCode === 27) {
            $(links).removeClass('active');
            $(innerElements).removeClass('open');
        }
    });

    // scroll to
    $(document).on('click', '.js-scroll-to', function (e) {
        var targetDiv = $(this).attr('href');
        $('html, body').animate({
            scrollTop: $(targetDiv).offset().top
        }, 1000);
        e.preventDefault();
    });

    // sliders
    var swiper1 = new Swiper('.js-slider-brands', { // eslint-disable-line
        loop: true,
        autoHeight: true,
        slidesPerView: 2,
        autoplay: {
            delay: 3500,
            disableOnInteraction: true
        },
        breakpoints: {
            640: {
                slidesPerView: 3
            },
            768: {
                slidesPerView: 4
            },
            1024: {
                slidesPerView: 6
            }
        }
    });

    var swiper2 = new Swiper('.js-slider-reviews', { // eslint-disable-line
        loop: true,
        autoplay: {
            delay: 3500,
            disableOnInteraction: true
        },
        pagination: {
            el: '.js-slider-reviews .swiper-pagination',
            clickable: true
        }
    });

    // tabs
    var tabs = function name(links, panels) {
        $(document).on('click', links, function (e) {
            if (!$(this).hasClass('active')) {
                $(links).removeClass('active').filter('a[href="' + this.hash + '"]').addClass('active');
                $(panels).hide().removeClass('open').filter(this.hash).show().addClass('open');
            }
            e.preventDefault();
        });
    };

    tabs('.js-tabs-links', '.js-tabs-panels');

    // modal
    var modal = '.js-modal',
        modalWrap = '.js-modal-wrap',
        modalOpen = '.js-modal-link',
        modalClose = 'js-modal-close';

    if ($(modalOpen).length) {

        // modal ready
        $(document.body).append('<div class="modal js-modal"><a href="#" class="modal__bg js-modal-close"></a><div class="modal__hold js-modal-wrap"></div></div>');

        // modal open
        $(document).on('click', modalOpen, function (e) {
            // get scrollbar width
            var scrollbarWidth = window.innerWidth - $(window).width();

            $.ajax({
                type: 'GET',
                url: $(this).attr('href'),
                success: function success(data) {
                    $(modal).addClass('open').find(modalWrap).html(data);
                    $('body').addClass('no-scroll');
                    $('body').css('padding-right', scrollbarWidth);
                    $(links).removeClass('active');
                    $(innerElements).removeClass('open');
                },
                error: function error() {
                    alert('fail');
                }
            });
            e.preventDefault();
        });

        // form submit
        $('body').on('submit', 'form.js-submit', function (e) {
            e.preventDefault();
            let form = $(this);

            form.find('.form_errors').remove();

            $.ajax({
                url: form.attr('action'),
                type: form.attr('method'),
                data: form.serialize(),
                success: function (data) {
                    form.find('.mcont').addClass('hidden');
                    form.find('.msuccess').removeClass('hidden');
                },
                error: function (error) {
                    if (typeof error.responseJSON.errors !== 'undefined') {
                        let errorsList = error.responseJSON.errors;
                        console.log(errorsList);
                        for (let key in errorsList) {
                            form.find('[data-err="' + key + '"]')
                                .after('<ul class="form_errors"><li>' + errorsList[key] + '</li></ul>')
                            ;
                        }
                    }
                }
            })
        });

        // modal close
        $(document).on('click keyup', function (e) {
            if ($(e.target).hasClass(modalClose) || e.keyCode === 27) {
                $(modal).removeClass('open').one('transitionend', function () {
                    $(modalWrap).empty();
                    $('body').removeClass('no-scroll');
                    $('body').css('padding-right', 0);
                });
                e.preventDefault();
            }
        });
    }

    // custom select
    if ($.fn.select2) {
        $('.js-select').select2({
            minimumResultsForSearch: Infinity
        });
    }

    // table responsive
    $('.body table:not([class])').each(function () {
        while (this.attributes.length > 0) {
            this.removeAttribute(this.attributes[0].name);
        }
        $(this).wrapAll('<div class="table-responsive"></div>');
    });

    $('#request-call').click(function () {
        fbq('track', 'Contact');
        gtag('event', 'Contact');
    });

    $('#place-order').click(function () {
        fbq('track', 'Lead');
        gtag('event', 'Lead');
    });
});
